
import React from 'react'
import Grid from '@material-ui/core/Grid';
import profPhoto from '../assets/imgs/profilePhoto.jfif'
import ZamzamLogo from '../assets/imgs/logo.png'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import Button from '@material-ui/core/Button';
import HandIcon from '../assets/imgs/HandIcon.svg'



function OurCommunitySupport() {


  return (

    <div className="ExpansionOurCommunitySupportContainer">

      <div className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Our Community support</h1>

        <div className="ExpansionCardContainer">
          <Grid container spacing={3}>

          <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={ZamzamLogo} /> 
                </div>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src="https://static.wixstatic.com/media/9cc235_8116c1d32c8d4fb9861f05c45d314f19~mv2.jpg/v1/fill/w_360,h_344,al_c,q_80,usm_0.66_1.00_0.01/Masjid%20logo.webp" /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>

            <div className="ButtonCenter">
              <Button className="ExpansionFillButton" variant="contained" href="#contained-buttons">
              <img className="ExpansionFillButtonIcon" src={HandIcon} /> Support us now</Button>
            </div>


          </Grid>
        </div>
      </div>


    </div>



  );
}


export default OurCommunitySupport