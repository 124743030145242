
import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PROPIMG from '../assets/imgs/propertyImg.png'
import { Link } from 'react-router-dom';

import ExpansionSummary from './ExpansionSummary'
import DonaitionCategories from './DonaitionCategories'


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


function TabsSection() {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <>
      <div className="ExpansionTabBar">
        <div className="ExpansionContainer">
          <Grid container spacing={3}>

            <Grid item md={8} xs={12}>
              <Tabs
                value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered >
                <Tab label="Description" />
                <Tab label="Updates" />
                <Tab label="Project phase" />
                <Tab label="Guests" />
                <Tab label="Testimonials" />
                <Tab label="Community" />
              </Tabs>
            </Grid>

          </Grid>

        </div>
      </div>

      <div className="ExpansionContainer">

        <div className="ExpansionTabsContainer">
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <div className="TabsContainerPannel">
                <h1>Please help us purchase this property before we lose it to someone else!</h1>
                <p>There are over 300,000 Muslims in our community and we’re still growing every year, Alhamdulilah! The Prophet, peace be upon him, said:</p>
                <div className="TabsContainerQoute">“Whoever removes hardship for a believer in this world, Allah will remove hardship from him on the Day of Judgment.”</div>
                <div className="propertyImg"><img src={PROPIMG} /></div>
                <p>Shaykh Abdullah Danish has authored over 20 books, has had over 1000 students, and built Masjid al-Badr in Brooklyn, NY 25 years ago from scratch! He is a gem that is loved by the Muslim community.</p>
                <p>Shaykh Abdullah Danish has authored over 20 books, has had over 1000 students.</p>
                <Link className="TextReadMore">READ MORE</Link>
              </div >
            </Grid>

            <Grid item md={4} xs={12}>
              <ExpansionSummary />
              {/* <DonaitionCategories /> */}
            </Grid>


          </Grid>
        </div>
      </div>


    </>


  );
}










export default TabsSection