
import React from 'react'
import Grid from '@material-ui/core/Grid';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import FooterLogo from '../assets/imgs/dc_logo.png'


function Footer() {
  return (

    <>
      <div className="ExpansionFooterContainer">


        <div className="ExpansionContainer">
          <Grid container spacing={3}>

            <Grid item md={4} sm={4} xs={12}>
              <div className="FooterTop">
                <h1>Organization Full Name</h1>
                <p>We are 501(c)(3) tax-exempted organization.</p>
                <p>Tax ID 99-9090909</p>
              </div>
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <div className="FooterTop">
                <h1>Address</h1>
                <p>2213 Mount Olive Road Atlanta, GA 30308</p>
              </div>
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <div className="FooterTop">
                <h1>Contact</h1>
                <p><PhoneRoundedIcon /> +1 (000) 222-9999</p>
                <p><EmailRoundedIcon /> hello@orgdomain.org</p>
              </div>
            </Grid>

          </Grid>

        </div>

        <div className="FooterTosSection">
          <div className="ExpansionContainer FooterTosSectionInner">
            <div>
              <a href="#">Terms of Services</a>
              <span>|</span>
              <a href="#">Privacy Policy</a>
            </div>
            <div className="CraftedText">Crafted with ❤ in islam</div>
          </div>
        </div>

      </div>

      <div className="FooterBottom">
        <div className="ExpansionContainer FooterBottomSectionInner">
            <p>© 2020 DEENCONNECT. ALL RIGHT RESERVED</p>
            <p className='footerLogo'>Powered by <a href="https://deenconnect.io/"><img src={FooterLogo}/></a></p>
            <div className="VisitText">Visit us - <a href="https://deenconnect.io/" target="blank">www.deenconnect.com</a></div>
        </div>
      </div>
    </>

  );
}










export default Footer