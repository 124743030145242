
import React from 'react'
import Header from './Header'
import Banner from './Banner'
import TabsSection from './TabsSection'
import OurGuests from './OurGuests'
import DonaitionCategoriesHorizontal from './DonaitionCategoriesHorizontal'
import OurCommunitySupport from './OurCommunitySupport'
import Footer from './Footer'
import ProjectPhase from './ProjectPhase'
import ConstructionUpdates from './ConstructionUpdates'
import Testimonials from './Testimonials'


import '../assets/css/Style.css';

function HomePage() {
  return (
    <div>
      <Header/>
      <Banner/>
      <TabsSection/>
      <ProjectPhase/>
      <DonaitionCategoriesHorizontal/>
      <ConstructionUpdates/>
      <OurGuests/>
      <Testimonials/>
      <OurCommunitySupport/>
      <Footer/>
    </div>
  );
}










  export default HomePage