
import React from 'react'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Button from '@material-ui/core/Button';



function ExpansionSummary() {


  return (

      <div className="DonaitionCategoriesContainer">
        <h1>The reward of goodness is nothing but goodness</h1>

            <Button variant="contained"  href="#contained-buttons">Give One Exterior Brick <ArrowForwardIosRoundedIcon /></Button>
            <Button variant="contained"  href="#contained-buttons">Give One Interior CMU Brick <ArrowForwardIosRoundedIcon /></Button>
            <Button variant="contained"  href="#contained-buttons">Give One Brick Mortar <ArrowForwardIosRoundedIcon /></Button>
            <Button variant="contained"  href="#contained-buttons">Give One ​Insulation Sheeting <ArrowForwardIosRoundedIcon /></Button>
            <Button variant="contained"  href="#contained-buttons">Give One Exterior Brick <ArrowForwardIosRoundedIcon /></Button>
            <Button variant="contained"  href="#contained-buttons">Give One Interior CMU Brick <ArrowForwardIosRoundedIcon /></Button>

      </div>



  );
}










export default ExpansionSummary