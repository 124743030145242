
import React from 'react'
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import HourglassFullRoundedIcon from '@material-ui/icons/HourglassFullRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';



function ProjectPhase() {


  return (

    <div className="ExpansionProjectPhaseContainer LighGreyContainer">
      <div className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Project Phase</h1>


        <div className="ProjectPhaseContainer">

          <div className="ProjectPhaseBox PhaseActive Done">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Architectural Plans</div>
            </div>
          </div>

          <div className="ProjectPhaseBox PhaseActive Inprogress">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Buildout</div>
            </div>
          </div>

          <div className="ProjectPhaseBox Default">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Expansion</div>
            </div>
          </div>

          <div className="ProjectPhaseBox Default">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Completion</div>
            </div>
          </div>


        </div>

      </div>

    </div>



  );
}


export default ProjectPhase